<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-3 shadow mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Attribute Products Details</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="goto()"
            >
              Back
            </button>
          </div>
        </div>
        <!-- ---------------------- -->
        <div class="row m-0 p-0 align-items-center">
          <div class="col-6 col-xl-2">
            <img
              v-if="product.length > 0 && product[0].img !== ''"
              :src="`${$cons.API_URL_IMG}${product[0].img}`"
              class="img-fluid img-products"
              alt=""
            />
            <img
              v-else
              src="@/assets/photo.png"
              class="img-fluid w-100"
              alt=""
            />
          </div>
          <div class="col-8" v-if="product.length > 0">
            <p class="font-1-2s text-main font-600">
              {{ product[0].prNameTH }}
            </p>
            <p>
              SKU <span class=" text-main mx-1">{{ product[0].skCode }}</span>
            </p>
          </div>
        </div>
        <div
          class="row m-0 p-0"
          v-if="
            attributeproductlist.length > 0 && !!attributeproductlist[0].CLASS
          "
        >
          <div
            class="col-12 m-0 p-0"
            v-for="(e, i) in attributeproductlist"
            :key="i"
          >
            <UpdateProductAttributev1
              :key="rekey + e.CLASS"
              :catWeb="e.CLASS"
              :skCode="e.skCode"
            />
          </div>
        </div>
        <!-- +--------------------------- -->

        <div class="row m-0 p-0">
          <div class="col-12 my-3 text-right">
            <button
              type="button"
              class="btn btn-sm bt-main px-4"
              @click="save()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateProductAttributev1 from "@/components/Manage/Attributes/UpdateProductAttribute_v1.vue";
export default {
  name: "AttributesProductsDetail",
  components: { UpdateProductAttributev1 },
  data() {
    return {
      rekey: Date.now() + 5,
      product: [],

      attributeproductlist: [],
    };
  },
  mounted() {
    this.$store.dispatch("attributeProductDetailAction/reval");
    this.get_data_production();
  },
  destroyed() {
    this.$store.dispatch("attributeProductDetailAction/reval");
  },
  methods: {
    async validate() {
      return new Promise(async (resolve, reject) => {
        let val_master = this.$store.getters[
          "attributeProductDetailAction/get_master"
        ];
        let val = this.$store.getters["attributeProductDetailAction/get_val"];
        // console.log(val_master);
        for await (let v of val_master) {
          let { data, attrGroupId } = v;
          let index = val.findIndex(
            (e) => String(e.attrGroupId).trim() === String(attrGroupId).trim()
          );
          let i = 0;

          if (index !== -1) {
            for await (let v2 of val[index].data) {
              let check = !!!v2;

              if (check) {
                let { required, attrlabelTH, catCode, labelAttrGroup } = data[
                  i
                ];
                if (String(required) === "1") {
                  resolve(
                    `กรุณาระบุ ${attrlabelTH} ภายใต้  ${labelAttrGroup}( ${catCode} )`
                  );
                }
              }
              i++;
            }
          } else {
            resolve(`ไม่พบข้อมูล`);
          }
        }
        // let data = this.selectattribute;

        // let i = 0;
        // for await (let v of data) {
        //   let check = !!!v;
        //   //   console.log(check);
        //   if (check) {
        //     // console.log(i);
        //     // console.log(this.attributelist[index].data[i]);
        //     let { required, attrlabelTH } = this.attributelist[i];
        //     if (String(required) === "1") {
        //       resolve(`กรุณาระบุ ${attrlabelTH}`);
        //     }
        //   }
        //   i++;
        // }

        resolve("OK");
      });
    },
    async save() {
      try {
        let val = this.$store.getters["attributeProductDetailAction/get_val"];
        let vali = await this.validate();
        if (vali !== "OK") {
          throw vali;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          for await (let v of val) {
            let { data } = v;
            let getAPI = await this.$serviceAPI.call_API(
              "post",
              `attribute/update_product_attribute_value`,
              data,
              1
            );
          }
          // let getAPI = await this.$serviceAPI.call_API(
          //   "post",
          //   `attribute/update_product_attribute_value`,
          //   this.selectattribute,
          //   1
          // );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.get_data_production();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        console.log(error);
      }
    },

    goto() {
      if (!!this.$route.query?.content) {
        this.$router.push({
          name: "Enrich Product Detail",
          params: { skcode: this.$route.params.skcode },
        });
      } else {
        this.$router.push({ name: "Attributes-Products" });
      }
    },

    async get_data_production() {
      try {
        let data = {
          mode: "skcode",
          find: this.$route.params.skcode,
          catCode: "",
          notmapping: "",
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_porduct_list`,
          data,
          1
        );
        // console.log(getAPI.data);
        this.attributeproductlist = getAPI.data;

        let _data = getAPI.data;
        if (_data.length > 0) {
          let check_null_image = await this.$serviceMain.chechnull(
            _data[0]?.images
          );

          let img =
            check_null_image === "" ? "" : String(_data[0]?.images).split("|");

          img = img[0] !== "" ? img[0] : img[1] !== "" ? img[1] : "";

          _data[0].img = img;

          this.product = _data;
          this.rekey = Date.now(0);

          let check_class = this.attributeproductlist[0].CLASS;

          if (!!!check_class) {
            throw `สินค้านี้ยังไม่ได้ผูก catweb / This product not mapping catweb`;
          }
        }
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    // const answer = window.confirm(
    //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    // );
    let confirm = await this.$serviceMain.showConfirmAlert(
      this,
      "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    );
    if (confirm) {
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style scoped>
.img-products {
  width: 150px;
  height: auto;
}
</style>
