<template>
  <div class="row mx-0 mb-3 p-0" :key="rekey">
    <div class="col-12">
      <hr />
      <p class="font-600">{{ `${labelAttrGroup} ( ${catWeb} )` }}</p>
    </div>
    <div class="col-4">
      <p>ข้อมูลเฉพาะสินค้า</p>
      <hr />
      <div
        class="row p-1 font-0-9s "
        v-for="(e, i) in dataattr"
        :key="i"
        :class="even(i) ? '' : 'att-even'"
      >
        <!-- {{e}} -->
        <div class="col-6 font-600">{{ e.PropName }}</div>
        <div class="col-6">{{ e.PropDetail }}</div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="col-8  ">
      <div class="row m-0 p-0">
        <div
          class="col-12 col-lg-6"
          v-for="(e, i) in attributelist"
          :key="e.attrGroupId + i"
        >
          <div class="form-group form-group-sm">
            <label
              >{{ e.attrlabelTH }}
              <span
                v-if="String(e.required) === '1'"
                class="badge badge-warning"
                >Required</span
              ></label
            >
            <multiselect
              :key="e.attrGroupId + i + rekeyChange"
              v-model="selectattribute[i]"
              placeholder="Search"
              @select="valChange(i)"
              :options="e.option"
              :clear-on-select="true"
              :allow-empty="false"
              deselect-label=""
              select-label=""
              selected-label=""
              label="labelTH"
              track-by="labelTH"
            >
              <div
                class="option__desc isDisabled"
                slot="option"
                slot-scope="props"
              >
                <span>{{ props.option.labelTH }} </span>
              </div>
            </multiselect>
            <!-- <select
              class="form-control form-control-sm"
              v-model="selectattribute[i]"
            >
              <option
                v-for="(ee, ii) in e.option"
                :key="e.attrGroupId + i + ii"
                :value="ee"
                :disabled="String(ee.active) === '0'"
                >{{ ee.labelTH }}
              </option>
            </select> -->
            <!-- {{ selectattribute[i] }} -->
          </div>
        </div>
      </div>
      <!-- <div class="row m-0 p-0">
        <div class="col-12 my-3 text-right">
          <button
            :disabled="attributelist.length === 0"
            type="button"
            class="btn btn-sm bt-main px-4"
            @click="save()"
          >
            Save
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateProductAttribute",

  props: ["catWeb", "skCode"],
  data() {
    return {
      rekey: Date.now() + 5,
      rekeyChange: Date.now() + 15,
      labelAttrGroup: "",
      attributelist: [],
      selectattribute: [],
      dataattr: [],
      keepattrGroupId: "",
    };
  },

  watch: {
    comlastChange: async function(v) {
      // console.log(
      //   (this.selectattribute[index] = await this.$store.getters[
      //     "attributeProductDetailAction/get_val"
      //   ])
      // );
      let { attrId } = v;
      let index = this.attributelist.findIndex(
        (e) => String(e.attrId).trim() === String(attrId).trim()
      );
      if (index !== -1) {
        let index_ms = this.$store.getters[
          "attributeProductDetailAction/get_master"
        ].findIndex(
          (e) =>
            String(e.attrGroupId).trim() === String(this.keepattrGroupId).trim()
        );

        if (index_ms !== -1) {
          this.selectattribute[index] = await this.$store.getters[
            "attributeProductDetailAction/get_val"
          ][index_ms].data[index];
          this.rekeyChange = Date.now();
        }
      }
    },
  },
  computed: {
    comlastChange: function() {
      return this.$store.getters["attributeProductDetailAction/get_lastChange"];
    },
  },
  mounted() {
    this.get_data_attributes();
    this.getPage7();
  },
  methods: {
    valChange(indax) {
      let data = this.selectattribute[indax];

      this.$store.dispatch("attributeProductDetailAction/changeval", data);
    },
    even(i) {
      if (i % 2 == 0) {
        return true;
      } else {
        return false;
      }
    },
    async getPage7() {
      try {
        let data = { skCode: this.skCode, catWeb: this.catWeb };

        let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
          "post",
          `attribute/get_page_seven_by_cat`,
          data,
          1
        );
        //  console.log(getAPI.data);
        this.dataattr = getAPI.data;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        console.log(error);
      }
    },
    async get_data_attributes() {
      try {
        this.selectattribute = [];
        let data = { skuCode: this.skCode, catWeb: this.catWeb };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_porduct_by_sku`,
          data,
          1
        );

        let arr = getAPI.data;
        // console.log(`${this.catWeb} :${JSON.stringify(arr[0])}}`);
        if (arr.length > 0) {
          this.labelAttrGroup = arr[0]?.labelAttrGroup;
        } else {
          this.$bvToast.toast(
            `Please Mapping Attribute in cat ${this.catWeb}`,
            {
              title: `Warning!`,
              variant: "warning",
              toaster: "b-toaster-top-right",
              solid: true,
              autoHideDelay: 30000,
            }
          );
        }

        arr.forEach((object) => {
          object["option"] = [];
        });

        this.$store.dispatch("loadingActions/onload");

        this.attributelist = arr;
        // console.log(this.attributelist);
        // ----- set data follow group ------
        for await (let v of this.attributelist) {
          let { attrId, attrGroupId, attrlabelTH } = v;
          this.keepattrGroupId = attrGroupId;
          this.selectattribute.push("");
          let get_option = await this.get_data_attributes_option(attrId);
          let index = this.attributelist.findIndex((e) => e.attrId === attrId);
          // console.log(index);
          if (index !== -1) {
            let arr = get_option;

            arr.forEach((object) => {
              object["skCode"] = this.skCode;
              object["labelAttr"] = attrlabelTH;
              object["attrGroupId"] = attrGroupId;
              object["attrId"] = attrId;
              object["$isDisabled"] =
                String(object.active) === "0" ? true : false;
            });
            this.attributelist[index].option = arr;
            // this.attributelist[index].option = get_option;
          }
        }
        // console.log(this.attributelist[0].attrGroupId);
        this.$store.dispatch("attributeProductDetailAction/setmaster", {
          attrGroupId: this.keepattrGroupId,
          data: this.attributelist,
        });
        // ----- set data follow group ------
        //------------ get value select in DB ------------------
        await this.get_data_value_option();

        this.$store.dispatch("loadingActions/onunload");
        this.rekey = Date.now();
        // console.log(this.attributelist);
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_data_attributes_option(attrId) {
      return new Promise(async (resolve, reject) => {
        try {
          let data = { attrId: attrId };

          let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
            "post",
            `attribute/get_attribute_option`,
            data,
            1
          );

          resolve(getAPI.data);
        } catch (error) {
          console.log(error);
          this.$serviceMain.showErrorAlert(
            this,
            error.message == undefined ? error : error.message
          );
        }
      });
    },
    async get_data_value_option() {
      try {
        let data = { skuCode: this.skCode, catWeb: this.catWeb };

        let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
          "post",
          `attribute/get_value_attribute_porduct_by_sku`,
          data,
          1
        );

        if (getAPI.data.length > 0) {
          let getdata = getAPI.data;
          for await (let a of getdata) {
            let { skCode, attrGroupId, attrDetail } = a;
            let _attrDetail = JSON.parse(attrDetail);
            // console.log(this.attributelist);
            for await (let v of _attrDetail) {
              //   console.log(v);
              let { attrId, option_id } = v;

              let index_att = this.attributelist.findIndex(
                (e) => e.attrId === attrId
              );
              //   console.log(index_att);
              if (index_att !== -1) {
                let {
                  labelEN: attrlabelEN,
                  labelTH: attrlabelTH,
                  option,
                } = this.attributelist[index_att];

                let index_option = option.findIndex((e) => e.id === option_id);
                //    console.log(option[index_option]);
                if (index_option !== -1) {
                  //  console.log(option[index_option]);

                  this.selectattribute[index_att] = {
                    ...option[index_option],
                  };
                }
              }

              // console.log("option");
              // console.log(option);
            }
          }
        }
        this.$store.dispatch("attributeProductDetailAction/setval", {
          attrGroupId: this.keepattrGroupId,
          data: this.selectattribute,
        });
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
